<template>
    <client-page>

            <v-container>
                <div class="ui-components-wrap">
                    <h3>{breakpoint} : sm<small>(min-width:576px)</small>, md<small>(min-width:768px)</small>, lg<small>(min-width:1024px)</small>, xl<small>(min-width:1200px)</small></h3>
                    <h3>{size} : 2px씩 늘어납니다</h3>

                    <v-divider class="my-20"></v-divider>
                <!-- Spacing -->
                    <h1>Spacing</h1>
                    <h3>{direction}</h3>
                    <p class="font-size-18">
                        <b>t</b> - margin-top and padding-top<br/>
                        <b>b</b> - margin-bottom and padding-bottom<br/>
                        <b>l</b> - margin-left and padding-left<br/>
                        <b>r</b> - margin-right and padding-right<br/>
                        <b>s</b> - margin-left/padding-left (in LTR mode) and margin-right/padding-right (in RTL mode)<br/>
                        <b>e</b> - margin-right/padding-right (in LTR mode) and margin-left/padding-left (in RTL mode)<br/>
                        <b>x</b> - both *-left and *-right<br/>
                        <b>y</b> - both *-top and *-bottom<br/>
                        <b>a</b> - the property in all directions
                    </p>
                    <h3>{size} : 0 ~ 300(px), n2 ~ n300(-2px ~ -300px)</h3>
                    <p class="font-size-18">padding일때, <u><b>p</b>{direction}-{breakpoint}-{size}</u></p>
                    <p class="font-size-18">margin일때, <u><b>m</b>{direction}-{breakpoint}-{size}</u></p>
                    <p class="font-size-18">예시) pa-md-16, mt-sm-10</p>
                <!-- //Spacing -->

                    <v-divider class="my-20"></v-divider>

                <!-- Sizing -->
                    <h1>Sizing</h1>
                    <h3>{abbrev}</h3>
                    <p class="font-size-18">
                        <b>w</b> - width<br/>
                        <b>mw</b> - max-width<br/>
                        <b>min-w</b> - min-width<br/>
                        <b>h</b> - height<br/>
                        <b>mh</b> - max-height<br/>
                        <b>min-h</b> - min-height<br/>
                        <b>vw</b> - width<br/>
                        <b>vh</b> - height<br/>
                        <b>min-vw</b> - min-width<br/>
                        <b>min-vh</b> - min-height<br/>
                    </p>
                    <h3>{size}</h3>
                    <p class="font-size-18">
                        px - <b>10px ~ 500px</b>(10px씩 늘어납니다), auto<br/>
                        % - <b>1 ~ 100</b>(1%씩 늘어납니다)<br/>
                        vw, vh - <b>100</b>만 있습니다<br/>
                    </p>
                    <br/>
                    <p class="font-size-18"><u>{abbrev}-{breakpoint}-{size}</u></p>
                    <p class="font-size-18">예시) w-10px, mh-md-100px, w-100, min-vw-100, vh-100</p>
                <!-- //Sizing -->

                    <v-divider class="my-20"></v-divider>

                <!-- Font -->
                    <h1>Font</h1>
                    <!-- S: Font Color -->
                    <h2>Color</h2>
                    <span class="primary--text">primary</span>
                    <span class="secondary--text">secondary</span>
                    <span class="accent--text">accent</span>
                    <span class="error--text">error</span>
                    <span class="info--text">info</span>
                    <span class="success--text">success</span>
                    <span class="warning--text">warning</span>
                    <!-- E: Font Color -->
                    <br/><br/>
                    <!-- S: Font Size -->
                    <h2>Size</h2>
                    <h3>{size} : 10 ~ 120</h3>
                    <p class="font-size-18"><u>font-size-{breakpoint}-{size}</u></p>
                    <p class="font-size-18">예시) font-size-14, font-size-md-16</p>
                    <!-- E: Font Size -->
                <!-- // Font -->

                    <v-divider class="my-20"></v-divider>

                <!-- Button -->
                    <h1>Button</h1>
                    <!-- S: Button Color -->
                    <h2>Color</h2>
                    <div class="v-btn--group">
                        <v-btn color="primary">primary Button</v-btn>
                        <v-btn color="secondary">secondary Button</v-btn>
                        <v-btn color="accent">accent Button</v-btn>
                        <v-btn color="error">error Button</v-btn>
                        <v-btn color="info">info Button</v-btn>
                        <v-btn color="success">success Button</v-btn>
                        <v-btn color="warning">warning Button</v-btn>
                    </div>
                    <!-- E: Button Color -->
                    <br/><br/>
                    <!-- S: Button Style -->
                    <h2>Style</h2>
                        <div class="v-btn--group">
                        <v-btn color="primary">Button</v-btn>
                        <v-btn tile color="primary">Tile Button</v-btn>
                        <v-btn rounded color="primary">Rounded Button</v-btn>
                        <v-btn outlined color="primary">Outlined Button</v-btn>
                        <v-btn rounded outlined color="primary">Outlined Rounded Button</v-btn>
                        <v-btn text color="primary">Text Button</v-btn>
                        <v-btn block color="primary">Block Button</v-btn>
                    </div>
                    <!-- E: Button Style -->
                    <br/>
                    <!-- S: Button Size -->
                    <h2>Size</h2>
                    <div class="v-btn--group">
                        <v-btn x-small>Extra small Button</v-btn>
                        <v-btn small>Small Button</v-btn>
                        <v-btn>Default Button</v-btn>
                        <v-btn large>Large Button</v-btn>
                        <v-btn x-large>Extra large Button</v-btn>
                    </div>
                    <br/>
                    <div class="v-btn--group">
                        <v-btn icon x-small><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn icon small><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn icon large><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn icon x-large><v-icon>mdi-pencil</v-icon></v-btn>
                    </div>
                    <br/>
                    <div class="v-btn--group">
                        <v-btn fab x-small><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn fab small><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn fab><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn fab large><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn fab x-large><v-icon>mdi-pencil</v-icon></v-btn>
                    </div>
                    <!-- E: Button Size -->
                    <br/>
                    <!-- S: Button Group -->
                    <h2>Group</h2>
                    <div class="v-btn--group v-btn--group--x-small">
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                    </div>
                    <div class="v-btn--group v-btn--group--small">
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                    </div>
                    <div class="v-btn--group">
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                    </div>
                    <div class="v-btn--group v-btn--group--large">
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                        <v-btn>Default Button</v-btn>
                    </div>
                    <!-- E: Button Group -->
                <!-- // Button -->

                    <v-divider class="my-20"></v-divider>

                <!-- Form -->
                    <!-- S: Text Field -->
                    <h1>Form</h1>
                    <h2>Input</h2>
                    <v-text-field hide-details placeholder="InlineInput" class="d-inline-flex"></v-text-field>
                    <br/><br/>
                    <v-text-field hide-details placeholder="DefaultInput"></v-text-field>
                    <br/>
                    <v-text-field hide-details class="v-input--large" placeholder="LargeInput"></v-text-field>
                    <br/>
                    <v-text-field filled hide-details placeholder="FilledInput"></v-text-field>
                    <br/>
                    <v-text-field outlined hide-details placeholder="OutlinedInput"></v-text-field>
                    <br/>
                    <v-text-field solo hide-details placeholder="SoloInput"></v-text-field>
                    <!-- E: Text Field -->

                    <br/><br/>
                    <!-- S: Select -->
                    <h2>Select</h2>
                    <v-select hide-details placeholder="Inline" class="d-inline-flex"></v-select>
                    <br/><br/>
                    <v-select hide-details placeholder="Standard"></v-select>
                    <br/>
                    <v-select filled hide-details placeholder="Filled"></v-select>
                    <br/>
                    <v-select outlined hide-details placeholder="Outlined"></v-select>
                    <br/>
                    <v-select solo hide-details placeholder="Solo"></v-select>
                    <!-- E: Select -->

                    <br/><br/>
                    <h2>Radio</h2>
                    <!-- S: Radio Color -->
                    <h3>Color</h3>
                    <v-radio-group row hide-details>
                        <v-radio label="primary" color="primary" value="primary"></v-radio>
                        <v-radio label="secondary" color="secondary" value="secondary"></v-radio>
                        <v-radio label="accent" color="accent" value="accent"></v-radio>
                        <v-radio label="error" color="error" value="error"></v-radio>
                        <v-radio label="info" color="info" value="info"></v-radio>
                        <v-radio label="success" color="success" value="success"></v-radio>
                        <v-radio label="warning" color="warning" value="warning"></v-radio>
                    </v-radio-group>
                    <!-- E: Radio Color -->
                    <br/>
                    <!-- S: Radio Direction -->
                    <h3>Direction</h3>
                    <v-radio-group column hide-details>
                        <v-radio label="RadioColumn"></v-radio>
                        <v-radio label="RadioColumn"></v-radio>
                    </v-radio-group>
                    <v-radio-group row hide-details>
                        <v-radio label="RadioRow"></v-radio>
                        <v-radio label="RadioRow"></v-radio>
                    </v-radio-group>
                    <!-- E: Radio Direction -->
                    <br/>
                    <!-- S: Radio States -->
                    <h3>States</h3>
                    <v-radio-group hide-details>
                        <v-radio label="default"></v-radio>
                        <v-radio label="disabled" disabled></v-radio>
                    </v-radio-group>
                    <!-- S: Radio States -->

                    <br/><br/>
                    <!-- Checkbox -->
                    <h2>Checkbox</h2>
                    <!-- S: Checkbox Color -->
                    <h3>Color</h3>
                    <div class="checkbox-group checkbox-group--row">
                        <v-checkbox label="primary" color="primary" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="secondary" color="secondary" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="accent" color="accent" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="error" color="error" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="info" color="info" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="success" color="success" value input-value="true" hide-details></v-checkbox>
                        <v-checkbox label="warning" color="warning" value input-value="true" hide-details></v-checkbox>
                    </div>
                    <!-- E: Checkbox Color -->
                    <br/>
                    <!-- S: Checkbox Direction -->
                    <h3>Direction</h3>
                    <div class="checkbox-group checkbox-group--column">
                        <v-checkbox label="CheckboxColumn" hide-details></v-checkbox>
                        <v-checkbox label="CheckboxColumn" hide-details></v-checkbox>
                    </div>
                    <div class="checkbox-group checkbox-group--row">
                        <v-checkbox label="CheckboxRow" hide-details></v-checkbox>
                        <v-checkbox label="CheckboxRow" hide-details></v-checkbox>
                    </div>
                    <!-- E: Checkbox Direction -->
                    <br/>
                    <!-- S: Checkbox States -->
                    <h3>States</h3>
                    <v-checkbox label="off" value hide-details></v-checkbox>
                    <v-checkbox label="on" input-value="true" value hide-details></v-checkbox>
                    <v-checkbox label="indeterminate" value indeterminate hide-details></v-checkbox>
                    <v-checkbox label="on disabled" input-value="true" value disabled hide-details></v-checkbox>
                    <v-checkbox label="off disabled" value disabled hide-details></v-checkbox>
                    <!-- S: Checkbox States -->
                    <br/><br/>
                    <!-- S: Textarea -->
                    <h2>Textarea</h2>
                    <v-textarea hide-details placeholder="Inline" class="d-inline-flex"></v-textarea>
                    <br/><br/>
                    <v-textarea hide-details placeholder="Default"></v-textarea>
                    <br/>
                    <v-textarea solo hide-details placeholder="Solo"></v-textarea>
                    <br/>
                    <v-textarea filled hide-details placeholder="Filled"></v-textarea>
                    <br/>
                    <v-textarea outlined hide-details placeholder="Outlined"></v-textarea>
                    <!-- E: Textarea -->
                <!-- // Form -->

                <v-divider class="my-20"></v-divider>

                <!-- Tab -->
                    <h1>Tab</h1>
                    <p>tab에 tab--sticky 클래스를 넣으면 상단에 붙습니다.</p>
                    <br/>
                    <!-- S: 기본 -->
                    <h2>모바일에서 셀렉트박스로 변경되는 탭</h2>
                    <div class="tab-wrap tab-wrap--selectbox">
                        <button class="tab-wrap__btn">메뉴명</button>
                        <ul class="tab">
                            <li class="tab__li active"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                            <li class="tab__li"><a class="tab__btn" href="#"><span>메뉴명</span></a></li>
                        </ul>
                    </div>
                    <!-- E: 기본 -->
                    <br/>
                    <!-- S: block -->
                    <h2>Block</h2>
                    <ul class="tab tab--block">
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: block -->
                    <br/>
                    <!-- S: punch -->
                    <h2>Punch</h2>
                    <ul class="tab tab--punch">
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: punch -->
                    <br/>
                    <!-- S: line -->
                    <h2>Line</h2>
                    <ul class="tab tab--line">
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: line -->
                    <br/>
                    <!-- S: underline  -->
                    <h2>Underline</h2>
                    <ul class="tab tab--underline">
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: underline -->
                    <br/>
                    <!-- S: underline-inline  -->
                    <h2>Underline Inline</h2>
                    <ul class="tab tab--underline-inline">
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: underline-inline -->
                    <br/>
                    <!-- S: Inline -->
                    <h2>Inline Tab</h2>
                    <ul class="tab tab--inline">
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li active"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                        <li class="tab__li"><button class="tab__btn"><span>메뉴명</span></button></li>
                    </ul>
                    <!-- E: underline -->
                <!-- // Tab -->

                <br/>
                <v-divider class="my-20"></v-divider>

                <!-- CommonText -->
                    <h1>Common Text</h1>
                    <!-- S: Title -->
                    <h2>Title</h2>
                    <h3>Size</h3>
                    <div class="tit-wrap">
                        <h2 class="tit tit--xs">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap">
                        <h2 class="tit tit--sm">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap">
                        <h2 class="tit">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap">
                        <h2 class="tit tit--lg">제목이들어갑니다</h2>
                    </div>

                    <h3>Align</h3>
                    <div class="tit-wrap">
                        <h2 class="tit">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap text-center">
                        <h2 class="tit">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap text-right">
                        <h2 class="tit">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-txt-wrap text-center">
                        <div class="tit-wrap">
                            <h2 class="tit">제목이들어갑니다</h2>
                        </div>
                        <p class="tit-txt">추가설명을입력해주세요</p>
                    </div>

                    <h3>text</h3>
                    <div class="sub-tit-wrap">
                        <h3 class="sub-tit">소제목</h3>
                        <div class="tit-wrap">
                            <h2 class="tit tit--sm">제목이들어갑니다</h2>
                        </div>
                    </div>

                    <div class="tit-txt-wrap">
                        <div class="tit-wrap">
                            <h2 class="tit">제목이들어갑니다</h2>
                        </div>
                        <p class="tit-txt">추가설명을입력해주세요</p>
                    </div>

                    <h3>Style</h3>
                    <div class="tit-wrap tit-wrap--line">
                        <h2 class="tit tit--sm">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-txt-wrap">
                        <div class="tit-wrap tit-wrap--line">
                            <h2 class="tit tit--sm">제목이들어갑니다</h2>
                        </div>
                        <p class="tit-txt">추가설명을입력해주세요</p>
                    </div>

                    <div class="tit-wrap tit-wrap--hyphen">
                        <h2 class="tit tit--sm">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap tit-wrap--dot">
                        <h2 class="tit">제목이들어갑니다</h2>
                    </div>

                    <div class="tit-wrap tit-wrap--unberline">
                        <h2 class="tit tit--sm">제목이들어갑니다</h2>
                    </div>
                    <!-- E: Title -->
                    <br/>
                    <!-- S: PageText -->
                    <h2>Page Text (공통으로 사용되는 텍스트스타일)</h2>
                    <h3>단락구분이 없을 경우</h3>
                    <p class="page-text">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                    <br/>
                    <h3>단락구분이 있을 경우</h3>
                    <div class="page-text">
                        <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                        <p>남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리 기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                        <p>가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                        <p>이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                    </div>
                    <!-- E: PageText -->
                <!-- //CommonText -->
                </div>
            </v-container>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },
    props: {
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<template>
    <client-page>
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">서비스이용약관</h2>
            </div>

            <terms-component code="service" />
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        TermsComponent,
    },
};
</script>

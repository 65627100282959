<template>
    <v-card tile outlined elevation="0" min-height="180" class="join-box">
        <v-card-text>
            <div v-if="terms?.content">
                <p class="textarea" name="terms" readonly v-html="terms?.content"></p>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    props: {
        code: { type: String, default: "tos" },
    },
    data: () => ({
        terms: null,
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            const { code } = this;
            const { terms } = await api.v1.terms.get({ code });
            this.terms = terms;
        },
    },
};
</script>

<style></style>
